<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <div class="bookmark-wrapper align-items-center d-none d-lg-flex pr-1 border-right">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <div class="align-items-center flex-grow-1">
      <AppBreadcrumb />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <no-responsive />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NoResponsive from '@core/layouts/components/app-navbar/components/NoResponsive.vue'
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    AppBreadcrumb,

    // Navbar Components
    DarkToggler,
    NoResponsive,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
