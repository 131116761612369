<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template
      v-if="userData"
      #button-content
    >
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || "Hola!" }}
        </p>
        <small>{{ getRole(userData.fk_role) }}</small>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <template
      v-else
      #button-content
    >
      <b-button
        variant="outline-primary"
        @click="$router.push('/login')"
      >
        Iniciar Sesión
      </b-button>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Cerrar Sesión</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BButton,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    getRole(role) {
      if (role === 1) return 'Administrador'
      if (role === 2) return 'Operario'
    },
    async logout() {
      if (await this.$store.dispatch('logout')) {
        this.$router.push('/login')
      }
    },
  },
}
</script>
