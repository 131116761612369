export default async function getNavMenuItems(userRole) {
  const navigationData = [
    {
      header: 'ALMACEN',
    },
    {
      title: 'Materiales',
      route: 'materiales-list',
      icon: 'PaperclipIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Pedidos',
      route: 'pedidos-list',
      icon: 'ShoppingCartIcon',
      meta: {
        hasAccess: [1, 2],
      },
    },
    {
      title: 'Entrega de materiales',
      route: 'albaranes-list',
      icon: 'SendIcon',
      meta: {
        hasAccess: [1, 2],
      },
    },
    {
      title: '------------------',
      route: '',
      icon: 'line',
      meta: {
        hasAccess: [1, 2],
      },
    },
    {
      title: 'Ped. a Proveedores',
      route: 'pedidos-proveedores-list',
      icon: 'ShoppingBagIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Recep. Material',
      route: 'materiales-recepcion-list',
      icon: 'PackageIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Histórico Recepción',
      route: 'historico-materiales-list',
      icon: 'ClockIcon',
      meta: {
        hasAccess: [1, 2],
      },
    },
    {
      header: 'PARTES DE TRABAJO',
    },
    {
      title: 'Partes de trabajo',
      route: 'partes-trabajo',
      icon: 'CoffeeIcon',
      meta: {
        hasAccess: [1, 2],
      },
    },
    {
      title: 'Mantenimientos',
      route: 'mantenimientos',
      icon: 'ToolIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Zonas',
      route: 'zonas',
      icon: 'MapIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Tareas',
      route: 'tareas-list',
      icon: 'Edit3Icon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      header: 'SISTEMA',
    },
    {
      title: 'Departamentos',
      route: 'departamentos-list',
      icon: 'ArchiveIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Empleados',
      route: 'empleados-list',
      icon: 'UsersIcon',
      meta: {
        hasAccess: [1, 2],
      },
    },
    {
      title: 'Proveedores',
      route: 'proveedores-list',
      icon: 'ShoppingBagIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Usuarios',
      route: 'users-list',
      icon: 'UserIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Configuración',
      route: 'configuracion',
      icon: 'SettingsIcon',
      meta: {
        hasAccess: [1],
      },
    },
  ]

  let filteredNavigationData = navigationData.filter(el => {
    if (el.header) return el
    return el.meta.hasAccess.includes(userRole)
  })

  if (userRole !== 2) {
    filteredNavigationData = [...[{
      header: 'DISEÑO DOCS',
    },
    {
      title: 'Plantillas',
      route: 'plantillas-list',
      icon: 'BookOpenIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Generar Doc',
      route: 'docs-new',
      icon: 'FileTextIcon',
      meta: {
        hasAccess: [1],
      },
    },
    {
      title: 'Listado Docs',
      route: 'docs-list',
      icon: 'ListIcon',
      meta: {
        hasAccess: [1],
      },
    }], ...filteredNavigationData]
  }

  return filteredNavigationData
}
